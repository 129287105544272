import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormGroupValue } from '../form.utils';
import { ValidatorService } from '../form/validators/validator.service';

export type BillingToForm = {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  address1: FormControl<string>;
  address2: FormControl<string>;
  city: FormControl<string>;
  state: FormControl<string>;
  zipcode: FormControl<string>;
};
export type BillingForm = {
  billingTo: FormGroup<BillingToForm>;
  sameBillingAddress: FormControl<boolean>;
};

@Injectable({ providedIn: 'root' })
export class BillingFormVm {
  fb = inject(FormBuilder);
  validator = inject(ValidatorService);

  create(partialValues: Partial<FormGroupValue<BillingForm>> = {}) {
    const form = this.fb.group<BillingForm>({
      billingTo: this.fb.group({
        firstName: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required, this.validator.isValidName()],
        }),
        lastName: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required, this.validator.isValidName()],
        }),
        address1: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required],
        }),
        address2: new FormControl('', { nonNullable: true }),
        city: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required],
        }),
        state: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required],
        }),
        zipcode: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required, this.validator.zipCodeFormat(), this.validator.zipCodeIsInRegion('state')],
        }),
      }),
      sameBillingAddress: new FormControl(true, { nonNullable: true }),
    });

    form.controls.billingTo.controls.zipcode.valueChanges.subscribe(() => {
      form.controls.billingTo.controls.state.markAsDirty();
      form.controls.billingTo.controls.state.markAsTouched();
      form.controls.billingTo.controls.state.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });

    form.controls.billingTo.controls.state.valueChanges.subscribe(() => {
      form.controls.billingTo.controls.zipcode.markAsDirty();
      form.controls.billingTo.controls.zipcode.markAsTouched();
      form.controls.billingTo.controls.zipcode.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });

    form.patchValue(partialValues);

    return form;
  }
}
